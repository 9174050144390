import axios from 'axios';
import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { actions as userActions } from 'reducers/user';
import { setCookie, deleteCookie } from 'utils/commonUtils';
import { ADMIN_COOKIE_NAME, KINDUSER_COOKIE_NAME } from '../../config/constants/constants';
import { API } from '../../config/constants/api';


export const resetPasswordFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.USER.RESET_PASSWORD,
    withCredentials: true,
    data,
  })
}

export function* resetPassword(params) {
  try {
    const response = yield call(resetPasswordFetch, params.data);
    yield put(userActions.setResetPassword(response.data));
  } catch (err) {
    yield put(userActions.setResetPassword({
      infoOp: 'Ha ocurrido un error contacte con su webmaster',
      operation: 'error',
    }))
  }
}

export const closeSessionFetch = (data) => {
  return axios({
    method: 'GET',
    url: API.USER.CLOSE_SESSION,
    withCredentials: true,
    data,
  })
}

export function* closeSession() {
  try {
    yield call(closeSessionFetch);
    deleteCookie(`${ADMIN_COOKIE_NAME}`);
    deleteCookie(`${KINDUSER_COOKIE_NAME}`);
    yield put(push('/'));
  } catch (err) {
    console.log(err);
  }
}

export const loginFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.USER.LOGIN,
    withCredentials: true,
    data,
  })
}

export function* loginSaga({ params }) {
  try {
    const responseAux = yield call(loginFetch, params);
    const response = responseAux.data;
    // Login comun
    const { logged, error, session } = response;
    if (logged) {
      if (session) {
        setCookie(`${ADMIN_COOKIE_NAME}`, session, 365);
      }
      yield put(push('/admin/inicio'));
      yield put(userActions.loginOK());
    } else {
      yield put(userActions.loginKO(error));
    }
  } catch (err) {
    yield put(userActions.loginKO());
  }
}

export const changePasswordFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.USER.CHANGE_PASSWORD,
    withCredentials: true,
    data,
  })
}

export function* changePassword({ params }) {
  try {
    const responseAux = yield call(changePasswordFetch, params);
    const response = responseAux.data;
    const { data, error } = response;
    if (error) {
      yield put(userActions.changePasswordKO(error));
    } else if (data) {
      yield put(userActions.changePasswordOK());
      deleteCookie(`${ADMIN_COOKIE_NAME}`);
      yield put(push('/'));
    }
  } catch (err) {
    yield put(userActions.changePasswordKO(`Error: ${err} contacta con su webmaster`));
  }
}

export const recoverEmailFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.USER.SEND_RECOVER_EMAIL,
    withCredentials: true,
    data,
  })
}

export function* sendRecoverEmail({ params }) {
  try {
    const response = yield call(recoverEmailFetch, params);
    const { data, error } = response;
    if (error) {
      yield put(userActions.sendEmailRecoverKO(error));
    } else if (data) {
      yield put(userActions.sendEmailRecoverOK());
    }
  } catch (err) {
    yield put(userActions.sendEmailRecoverKO('Error desconocido, contacte con su webmaster'));
  }
}

