export const types = {
  // SECTIONS
  GET_SECTIONS: 'ADMIN/GET_SECTIONS',
  GET_SECTIONS_OK: 'ADMIN/GET_SECTIONS_OK',
  GET_SECTIONS_KO: 'ADMIN/GET_SECTIONS_KO',
  SET_SECTION_SELECTED: 'ADMIN/SET_SECTION_SELECTED',
  // PRIVILEGES
  GET_PRIVILEGES_BY_ID: 'ADMIN/GET_PRIVILEGES_BY_ID',
  SET_PRIVILEGES_BY_ID: 'ADMIN/SET_PRIVILEGES_BY_ID',
  SET_NEW_PRIVILEGES: 'ADMIN/SET_NEW_PRIVILEGES',
  // USERS
  GET_USERS: 'ADMIN/GET_USERS',
  DELETE_USER: 'ADMIN/DELETE_USER',
  SET_DELETE_USER_RESULT: 'ADMIN/SET_DELETE_USER_RESULT',
  SET_USERS: 'ADMIN/SET_USERS',
  CREATE_NEW_USER: 'ADMIN/CREATE_NEW_USER',
  CREATE_NEW_USER_RESULT: 'ADMIN/CREATE_NEW_USER_RESULT',
  // GET TEXTS
  GET_TEXTS: 'ADMIN/GET_TEXTS',
  SET_TEXTS: 'ADMIN/SET_TEXTS',
  SET_TEXT_RESULT: 'ADMIN/SET_TEXT_RESULT',
  SAVE_TEXT: 'ADMIN/SAVE_TEXT',
}

export const initialState = {
  sections: null,
  sectionsFetching: false,
  sectionSelected: null,
  sectionsError: false,
  //
  // texts
  texts: null,
  textRes: {
    data: null,
    infoOp: null,
  },
  // PRIVILEGES
  privileges: {
    data: null,
    infoOp: null,
  },
  // USERS
  users: {
    res: null,
    infoOp: null,
  },
  deleteUserResult: null,
  createNewUserResult: {
    res: null,
    infoOp: null,
  },
}

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    /* SECTIONS BEGINS */
    case types.GET_SECTIONS:
      return {
        ...state, sections: null, sectionsFetching: true, sectionsError: false,
      }
    case types.GET_SECTIONS_OK: {
      const { sections } = action.payload;
      return {
        ...state, sections, sectionsFetching: false, sectionsError: false,
      }
    }
    case types.GET_SECTIONS_KO:
      return { ...state, sections: null, sectionsError: false }
    /* SECTIONS ENDS */
    /* TEXTS BEGINS */
    case types.GET_TEXTS: return { ...state }
    case types.SET_TEXTS: return { ...state, texts: action.texts }
    case types.SET_TEXT_RESULT: return { ...state, textRes: action.result }
    /* TEXTS ENDS */
    /* PRIVILEGES */
    case types.SET_NEW_PRIVILEGES:
      return { ...state, changingPrivileges: true }
    case types.GET_PRIVILEGES_BY_ID:
      return { ...state, privileges: { operationRes: 'loading', data: null, infoOp: null } }
    case types.SET_PRIVILEGES_BY_ID: {
      return {
        ...state,
        privileges: {
          operationRes: action.payload.result.operation,
          data: action.payload.result.res,
          infoOp: null,
        },
      }
    }
    /* USERS BEGINS */
    case types.GET_USERS:
      return { ...state, users: { operationRes: 'loading', data: null, infoOp: null } }
    case types.SET_USERS:
      return {
        ...state,
        users: {
          operationRes: action.payload.result.operation,
          data: action.payload.result.res,
          infoOp: null,
        },
      }
    case types.CREATE_NEW_USER:
      return { ...state, createNewUserResult: { res: 'loading', infoOp: '' } }
    case types.CREATE_NEW_USER_RESULT:
      return { ...state, createNewUserResult: action.payload.result }
    case types.DELETE_USER:
      return { ...state, deleteUserResult: { infoOp: 'Eliminando...', operation: 'loading' } }
    case types.SET_DELETE_USER_RESULT:
      return { ...state, deleteUserResult: action.result }
    /* USERS ENDS */
    case types.SET_SECTION_SELECTED: {
      return { ...state, sectionSelected: action.section }
    }
    default: return { ...state }
  }
}


export const actions = {
  getSections: () => ({ type: types.GET_SECTIONS }),
  getSectionsOK: sections => ({ type: types.GET_SECTIONS_OK, payload: { sections } }),
  getSectionsKO: () => ({ type: types.GET_SECTIONS_KO }),
  setSectionSelected: section => ({ type: types.SET_SECTION_SELECTED, section }),
  // PRIVILEGES
  getPrivilegesById: idUser => ({ type: types.GET_PRIVILEGES_BY_ID, idUser }),
  setPrivilegesById: result => ({ type: types.SET_PRIVILEGES_BY_ID, payload: { result } }),
  setNewPrivileges: (strPriv, idUser) => ({
    type: types.SET_NEW_PRIVILEGES, data: { strPriv, idUser },
  }),
  // USERS
  getUsers: () => ({ type: types.GET_USERS }),
  deleteUser: id => ({ type: types.DELETE_USER, id }),
  setDeleteUserResult: result => ({ type: types.SET_DELETE_USER_RESULT, result }),
  setUsers: result => ({ type: types.SET_USERS, payload: { result } }),
  createNewUser: data => ({ type: types.CREATE_NEW_USER, params: { ...data } }),
  createNewUserResult: result => ({ type: types.CREATE_NEW_USER_RESULT, payload: { result } }),
  // TEXTS
  getTexts: () => ({ type: types.GET_TEXTS }),
  setTexts: texts => ({ type: types.SET_TEXTS, texts }),
  saveText: textData => ({ type: types.SAVE_TEXT, textData }),
  setTextResult: result => ({ type: types.SET_TEXT_RESULT, result }),
}

export default AdminReducer;
