import AdminLogo from 'assets/admin/logo/logop.png';
import IconPanel from 'assets/web/iconPanel.png';
import BgInput from 'assets/admin/form/input/bginput.jpg';
import DefaultUser from 'assets/admin/icons/sections/ui-user.svg';
import error403 from 'assets/web/error/403.jpg';
import error404 from 'assets/web/error/404.jpg';
import error500 from 'assets/web/error/500.jpg';
import footerError from 'assets/web/error/footer.jpg';

export {
  AdminLogo,
  IconPanel,
  BgInput,
  DefaultUser,
  error403,
  error404,
  error500,
  footerError,
};
