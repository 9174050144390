import merge from 'deepmerge';

export const types = {
  // FULL RESET
  FULL_RESET: 'FORM/FULL_RESET',
  // FILES
  UPLOAD_FILES: 'FORM/UPLOAD_FILES',
  SET_UPLOAD_FILES: 'FORM/SET_UPLOAD_FILES',
  ADD_UPLOAD_FILES: 'FORM/ADD_UPLOAD_FILES',
  SET_UPLOAD_PERCENTAGE: 'FORM/SET_UPLOAD_PERCENTAGE',
  DELETE_UPLOADED_FILE: 'FORM/DELETE_UPLOADED_FILE',
  // ENTITIYES
  GET_ENTITY_INFO: 'FORM/GET_ENTITY_INFO',
  GET_ENTITY_INFO_SUCCESS: 'FORM/GET_ENTITY_INFO_SUCCESS',
  GET_ENTITY_INFO_ERROR: 'FORM/GET_ENTITY_INFO_ERROR',
  // DELETE
  DELETE_ENTITY: 'FORM/DELETE_ENTITY',
  DELETE_ENTITY_RESULT: 'FORM/DELETE_ENTITY_RESULT',
  // INSERT
  INSERT_ENTITY: 'FORM/INSERT_ENTITY',
  INSERT_ENTITY_RESULT: 'FORM/INSERT_ENTITY_RESULT',
  // SHOW
  GET_ENTITY_ROWS: 'FORM/GET_ENTITY_ROWS',
  SET_ENTITY_ROWS: 'FORM/SET_ENTITY_ROWS',
  // UPDATE
  UPDATE_ENTITY: 'FORM/UPDATE_ENTITY',
  SET_UPDATE_RESULT: 'FORM/SET_UPDATE_RESULT',
  // PDF
  /* creations actions */
  CREATE_ENTITY_PDF: 'FORM/CREATE_ENTITY_PDF',
  CREATE_ENTITY_PDF_RESULT: 'FORM/CREATE_ENTITY_PDF_RESULT',
  /* exist actions */
  CHECK_ENTITY_PDF: 'FORM/CHECK_ENTITY_PDF',
  CHECK_ENTITY_PDF_RESULT: 'FORM/CHECK_ENTITY_PDF_RESULT',
  /* RESET */
  RESET_ENTITY_PDF: 'FORM/RESET_ENTITY_PDF',
}

export const initialState = {
  entities: {},
  // building form
  errorEntity: false,
  fetchingEntity: false,
  // FILEs
  uploadedFiles: null,
  uploadedFilesResult: null,
  uploadPercentage: 0,
  // SHOW
  entityRows: null,
  // DELETE:
  deleteEntityResult: null,
  // INSERT
  insertEntityResult: null,
  // UPDATE
  updateEntityResult: null,
  // PDF:
  /* creation result */
  pdfEntityResult: null,
  /* exist result */
  pdfEntityCheck: null,
}

const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    // FULL RESET
    case types.FULL_RESET: {
      return {
        ...initialState,
      }
    }
    // GET_ENTITY_INFO
    case types.GET_ENTITY_INFO: {
      return {
        ...state,
        fetchingEntity: true,
        errorEntity: false,
      }
    }
    case types.GET_ENTITY_INFO_SUCCESS: {
      const { type, data } = action.payload;
      const newEntities = { ...state.entities };
      newEntities[type] = data;
      return {
        ...state,
        entities: newEntities,
        fetchingEntity: false,
        errorEntity: false,
      }
    }
    // FILES
    case types.DELETE_FILES: {
      return {
        ...state,
        deletedFiles: null,
        deleteFilesResult: {
          infoOp: 'fetching',
          operation: 'loading',
        },
      }
    }
    case types.SET_DELETE_FILES: {
      return {
        ...state,
        deletedFiles: action.result.data,
        deleteFilesResult: {
          infoOp: action.result.infoOp,
          operation: action.result.operation,
        },
      }
    }
    case types.UPLOAD_FILES: {
      return {
        ...state,
        uploadedFilesResult: {
          infoOp: 'fetching',
          operation: 'loading',
        },
      }
    }
    case types.SET_UPLOAD_FILES: {
      return {
        ...state,
        uploadedFiles: action.result.data,
        uploadedFilesResult: {
          infoOp: action.result.infoOp,
          operation: action.result.operation,
        },
      }
    }
    case types.ADD_UPLOAD_FILES: {
      const files = merge(state.uploadedFiles, action.result.data)
      return {
        ...state,
        uploadedFiles: files,
        uploadedFilesResult: {
          infoOp: action.result.infoOp,
          operation: action.result.operation,
        },
      }
    }
    case types.SET_UPLOAD_PERCENTAGE: {
      return {
        ...state,
        uploadPercentage: action.percentage,
      }
    }
    case types.DELETE_UPLOADED_FILE: {
      return {
        ...state,
        uploadPercentage: action.percentage,
      }
    }
    // DELETE_ENTITY
    case types.DELETE_ENTITY: {
      return {
        ...state,
        deleteEntityResult: {
          infoOp: 'Eliminando...',
          operation: 'loading',
        },
      }
    }
    case types.DELETE_ENTITY_RESULT: {
      return {
        ...state,
        deleteEntityResult: action.res,
      }
    }
    // SHOW
    case types.GET_ENTITY_ROWS: {
      return {
        ...state,
        entityRows: null,
        updateEntityResult: null,
      }
    }
    case types.SET_ENTITY_ROWS: {
      return {
        ...state,
        entityRows: action.rows,
      }
    }
    // INSERT_ENTITY
    case types.INSERT_ENTITY: {
      return {
        ...state,
        insertingEntity: {
          infoOp: 'Creando...',
          operation: 'loading',
        },
      }
    }
    case types.INSERT_ENTITY_RESULT: {
      return {
        ...state,
        insertEntityResult: action.res,
      }
    }
    // UPDATE_ENTITY
    case types.UPDATE_ENTITY: {
      return {
        ...state,
        updateEntityResult: {
          infoOp: 'Modificando...',
          operation: 'loading',
        },
      }
    }
    case types.SET_UPDATE_RESULT: {
      return {
        ...state,
        updateEntityResult: action.params,
      }
    }
    // PDF
    /* creation result */
    case types.CREATE_ENTITY_PDF: {
      return {
        ...state,
        pdfEntityResult: {
          infoOp: 'Generando...',
          operation: 'loading',
        },
      }
    }
    case types.CREATE_ENTITY_PDF_RESULT: {
      return {
        ...state,
        pdfEntityResult: action.res,
      }
    }
    /* exist result */
    case types.CHECK_ENTITY_PDF: {
      return {
        ...state,
        pdfEntityCheck: {
          infoOp: 'Generando...',
          operation: 'loading',
        },
      }
    }
    case types.CHECK_ENTITY_PDF_RESULT: {
      return {
        ...state,
        pdfEntityCheck: action.res,
      }
    }
    /* reset pdf features */
    case types.RESET_ENTITY_PDF: {
      return {
        ...state,
        pdfEntityCheck: null,
        pdfEntityResult: null,
      }
    }
    default:
      return state
  }
}

export const actions = {
  // reset
  fullReset: () => ({ type: types.FULL_RESET }),
  // files
  uploadFiles: ({ files, uploadedFiles, update, kindOfFile }) => ({
    type: types.UPLOAD_FILES, data: { files, uploadedFiles, update, kindOfFile },
  }),
  setUploadfiles: result => ({ type: types.SET_UPLOAD_FILES, result }),
  addUploadfiles: result => ({ type: types.ADD_UPLOAD_FILES, result }),
  setUploadPercentage: percentage => ({ type: types.SET_UPLOAD_PERCENTAGE, percentage }),
  
  deleteUploadedFile: ({ fileName, kindOfFile }) => ({ type: types.DELETE_UPLOADED_FILE, data: { fileName, kindOfFile } }),
  // entities
  getEntity: params => ({ type: types.GET_ENTITY_INFO, params }),
  getEntityOK: params => ({ type: types.GET_ENTITY_INFO_SUCCESS, payload: params }),
  getEntityKO: () => ({ type: types.GET_ENTITY_INFO_ERROR }),
  // delete entity
  deleteEntity: params => ({ type: types.DELETE_ENTITY, params }),
  setDeleteEntityResult: res => ({ type: types.DELETE_ENTITY_RESULT, res }),
  // Insert entity
  insertEntity: data => ({ type: types.INSERT_ENTITY, data }),
  insertEntityResult: res => ({ type: types.INSERT_ENTITY_RESULT, res }),
  // show
  getEntityRows: data => ({ type: types.GET_ENTITY_ROWS, data }),
  setEntityRows: rows => ({ type: types.SET_ENTITY_ROWS, rows }),
  // update
  updateEntity: params => ({ type: types.UPDATE_ENTITY, params }),
  setUpdateEntityResult: params => ({ type: types.SET_UPDATE_RESULT, params }),
  // pdf
  /* create actions */
  createEntityPDF: params => ({ type: types.CREATE_ENTITY_PDF, params }),
  createEntityPDFResult: res => ({ type: types.CREATE_ENTITY_PDF_RESULT, res }),
  /* exist actions */
  checkEntityPDF: params => ({ type: types.CHECK_ENTITY_PDF, params }),
  setCheckEntityPDFResult: res => ({ type: types.CHECK_ENTITY_PDF_RESULT, res }),
  /* reset actions */
  resetEntityPdf: () => ({ type: types.RESET_ENTITY_PDF }),
}

export default FormReducer;
