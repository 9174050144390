import { all, takeLatest } from 'redux-saga/effects';
import * as userSagas from './user';
import * as formSagas from './form';
import * as adminSagas from './admin';
import * as uiSagas from './ui';
import { types as userTypes } from '../reducers/user';
import { types as formTypes } from '../reducers/form';
import { types as adminTypes } from '../reducers/admin';
import { types as uiTypes } from '../reducers/ui';

export default function* rootSaga() {
  yield all([
    /* client */
    takeLatest(uiTypes.GET_MAIN_INFO, uiSagas.getMainInfo),
    /* WEB COMMONS */
    takeLatest(uiTypes.SEND_MAIL, uiSagas.sendMail),
    /* PANEL */
    /* PRIVILEGES */
    takeLatest(adminTypes.SET_NEW_PRIVILEGES, adminSagas.setPrivileges),
    takeLatest(adminTypes.GET_PRIVILEGES_BY_ID, adminSagas.getPrivileges),
    /* USERS */
    takeLatest(adminTypes.GET_USERS, adminSagas.getUsers),
    takeLatest(adminTypes.CREATE_NEW_USER, adminSagas.createNewUser),
    takeLatest(adminTypes.DELETE_USER, adminSagas.deleteUser),
    /* SECTIONS */
    takeLatest(adminTypes.GET_SECTIONS, adminSagas.getSections),
    /* FORM */
    /* FILES */
    takeLatest(formTypes.UPLOAD_FILES, formSagas.uploadFiles),
    takeLatest(formTypes.DELETE_UPLOADED_FILE, formSagas.deleteUploadedFile),
    /* DELETE */
    takeLatest(formTypes.DELETE_ENTITY, formSagas.deleteEntity),
    /* UPDATE */
    takeLatest(formTypes.UPDATE_ENTITY, formSagas.updateEntity),
    /* SHOW */
    takeLatest(formTypes.GET_ENTITY_ROWS, formSagas.getEntityRows),
    /* INSERT */
    takeLatest(formTypes.INSERT_ENTITY, formSagas.insertEntity),
    /* COMMONS */
    takeLatest(formTypes.GET_ENTITY_INFO, formSagas.getEntity),
    /* PDF */
    takeLatest(formTypes.CREATE_ENTITY_PDF, formSagas.createEntityPdf),
    takeLatest(formTypes.CHECK_ENTITY_PDF, formSagas.checkEntityPdf),
    /* USER */
    takeLatest(userTypes.LOGIN, userSagas.loginSaga),
    takeLatest(userTypes.CHANGE_PASSWORD, userSagas.changePassword),
    takeLatest(userTypes.SEND_EMAIL_RECOVER, userSagas.sendRecoverEmail),
    takeLatest(userTypes.RESET_PASSWORD, userSagas.resetPassword),
    takeLatest(userTypes.CLOSE_SESSION, userSagas.closeSession),
  ])
}
