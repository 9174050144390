import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './commonStyles/uxux.scss';

const mainSetup = require('../config/mainSetup');

ReactDOM.render(
  <App />,
  document.getElementById(mainSetup.projectId),
);

// Check if hot reloading is enable. If it is, changes won't reload the page.
// This is related to webpack-dev-server and works on development only.
if (module.hot) {
  module.hot.accept();
}
