import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from './ErrorBoundary';
import RenderApp from './RenderApp';
import store, { history } from './store';

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary history={history}>
          <RenderApp />
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>
  )
};

export default App;
