import axios from 'axios';
import { call, put, select, all, delay } from 'redux-saga/effects';
import { actions as uiActions } from 'reducers/ui';
import { API } from '../../config/constants/api';

/* COMMON FOR EVERY WEB */
export const getEntity = data => (
  axios({
    method: 'POST',
    url: API.FORM.GET_ENTITY_ROWS,
    withCredentials: true,
    data,
  })
)

export const mailFetch = data => (
  axios({
    method: 'POST',
    url: API.USER.SEND_MAIL,
    withCredentials: true,
    data,
  })
)
export function* sendMail({ data }) {
  try {
    const response = yield call(mailFetch, data);
    const { infoOp, operation } = response.data;
    yield put(uiActions.setEmailResult({
      infoOp,
      operation,
    }));
  } catch (err) {
    yield put(uiActions.setEmailResult({
      infoOp: '',
      operation: 'loading',
    }))
  }
}

function* loopGetEntity(reqData) {
  const { finalName } = reqData;
  const response = yield call(getEntity, reqData);
  return {
    finalName,
    data: response.data.data,
  };
}


/* CLIENT THINGS */
export const getMainInfoState = state => state.ui.mainInfo;

function compararPorNombre(a, b) {
  if (a.nombre < b.nombre) {
    return -1;
  }
  if (a.nombre > b.nombre) {
    return 1;
  }
  return 0;
}

export function* getMainInfo() {
  try {
    const lastMainInfo = yield select(getMainInfoState);
    if (!lastMainInfo || !lastMainInfo.data) {
      const allMyRequest = [];
      const imagenesR = {
        finalName: 'imagenes',
        entity: 'imagen',
        fieldArray: ['idImagen', 'src', 'width', 'height'],
      }
      const archivosR = {
        finalName: 'archivos',
        entity: 'archivo',
        fieldArray: ['idArchivo', 'name', 'path'],
      }
      const productosR = {
        finalName: 'producto',
        entity: 'producto',
        fieldArray: ['idProducto', 'nombre', 'marca', 'archivo'],
      }
      const marcaR = {
        finalName: 'marca',
        entity: 'marca',
        fieldArray: ['idMarca', 'nombre', 'enlace', 'imagen'],
      }
      const legalR = {
        finalName: 'legal',
        entity: 'legal',
        fieldArray: ['idLegal', 'nombre', 'contenido'],
      }
      const portadaR = {
        finalName: 'portada',
        entity: 'portada',
        fieldArray: ['idPortada', 'nombre', 'title', 'description', 'imagen'],
      }

      allMyRequest.push(imagenesR);
      allMyRequest.push(archivosR);
      allMyRequest.push(productosR);
      allMyRequest.push(marcaR);
      allMyRequest.push(legalR);
      allMyRequest.push(portadaR);

      const allInfo = {};
      const allMyInfo = yield all(
        allMyRequest.map(currentReqData => loopGetEntity(currentReqData))
      );
      allMyInfo.forEach((entity) => {
        const { finalName, data } = entity;
        allInfo[finalName] = data;
      })


      /* NORMALIZO INFO */
      const productos = [];
      allInfo.producto.forEach((itemProducto) => {
        const currentProduct = { ...itemProducto };
        const { marca, archivo } = itemProducto;
        // extraigo su marca
        if (marca) {
          const currentMarca = allInfo.marca.find((itemMarca) => {
            return `${itemMarca.idMarca}` === `${marca}`;
          });
          if (currentMarca) {
            currentProduct.brand = {
              idBrand: currentMarca.idMarca,
              name: currentMarca.nombre,
              link: currentMarca.enlace,
            };
          }
          delete currentProduct.marca;
        }
        // extraigo su archivo
        if (archivo) {
          const idArchivos = archivo.split(',');
          const currentArchivos = [];
          idArchivos.forEach((idArchivo) => {
            const currentArchivo = allInfo.archivos.find(itemArchivo => `${itemArchivo.idArchivo}` === `${idArchivo}`);
            currentArchivos.push(currentArchivo);
          })
          currentProduct.files = currentArchivos;
        }
        productos.push(currentProduct);
      })

      const marcas = []
      allInfo.marca.forEach((itemMarca) => {
        const currentMarca = { ...itemMarca }
        const { idMarca } = currentMarca;
        const { imagen } = currentMarca;
        const productsOfaBranch = [];
        productos.forEach((itemProducto) => {
          const { brand } = itemProducto;
          if (brand && brand.idBrand) {
            if (`${idMarca}` === `${brand.idBrand}`) {
              productsOfaBranch.push(itemProducto);
            }
          }
        })

        if (imagen) {
          const idImagenes = imagen.split(',');
          const currentImagenes = [];
          idImagenes.forEach((idImagen) => {
            if (currentImagenes.length === 0) {
              const currentImg = allInfo.imagenes.find(itemImg => `${itemImg.idImagen}` === `${idImagen}`);
              currentImagenes.push(currentImg);
            }
          })

          if (currentImagenes.length === 1) {
            currentMarca.imagen = currentImagenes;
          }
        }

        currentMarca.productos = productsOfaBranch;
        marcas.push(currentMarca);
      })
      marcas.sort(compararPorNombre);
      const portadas = [];
      allInfo.portada.forEach((item) => {
        const newPortada = { ...item }
        const { imagen } = item;
        const pictures = imagen.split(',');
        const firstPic = pictures[0];
        const currentImagen = allInfo.imagenes.find(itemImage => `${itemImage.idImagen}` === `${firstPic}`);
        if (currentImagen) {
          newPortada.imagen = currentImagen;
          portadas.push(newPortada);
        }
      })

      yield put(uiActions.setMainInfo({
        data: {
          productos,
          marcas,
          portadas,
          legal: allInfo.legal,
        },
        infoOp: 'success',
      }));
    }
  } catch (err) {
    console.log(err);
    yield put(uiActions.setMainInfo({
      data: null,
      infoOp: 'error',
    }));
  }
}
